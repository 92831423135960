import { useMemo } from 'react';
import { Grid, Box, Flex, Container, Button } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import useLocale from '../lib/useLocale';
import { SanityCategory, SanityProduct } from '../../graphql-types';
import OutOfStock from '../components/OutOfStock';
import CategoryHeader from '../components/CategoryHeader';
import ProductCard from '../components/ProductCard';

interface CataloguePageProps extends PageProps {
    data: {
        categories: {
            nodes: Array<SanityCategory>;
        };
        products: {
            nodes: Array<SanityProduct>;
        };
    };
    pageContext: {
        category: string;
    };
}

const Catalogue = ({ data, pageContext }: CataloguePageProps) => {
    const { translate } = useLocale();
    const { category: categoryId } = pageContext;
    const { categories, products } = data;
    const category = useMemo(() => {
        if (categoryId)
            return categories.nodes.find(item => item.id === categoryId);
        return null;
    }, [categoryId, categories.nodes]);

    return (
        <Box pb={12}>
            <CategoryHeader category={category} />
            <Container maxW="container.lg">
                <Flex
                    wrap="wrap"
                    pt={4}
                    pb={8}
                    justifyContent="center"
                    textTransform="uppercase"
                >
                    <Button
                        variant="ghost"
                        as={GatsbyLink}
                        to="/catalogo"
                        colorScheme={!categoryId ? 'primary' : 'primary.900'}
                    >
                        Todos
                    </Button>
                    {categories.nodes.map(item => (
                        <Button
                            key={item.id}
                            variant="ghost"
                            as={GatsbyLink}
                            to={`/catalogo/${item?.slug?.current}`}
                            colorScheme={
                                categoryId === item.id
                                    ? 'primary'
                                    : 'primary.900'
                            }
                        >
                            {item.title ? translate(item.title) : ''}
                        </Button>
                    ))}
                </Flex>
                {!products.nodes?.length && <OutOfStock />}
                <Grid
                    templateColumns="repeat(auto-fill, minmax(18rem, 1fr))"
                    gap={8}
                >
                    {products.nodes.map(item => (
                        <ProductCard key={item.id} product={item} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export const query = graphql`
    query CatologoQuery($category: [String], $language: String!) {
        categories: allSanityCategory(
            filter: { image: { asset: { _id: { ne: null } } } }
        ) {
            nodes {
                ...SanityCategoryFragment
            }
        }
        products: allSanityProduct(
            filter: {
                available: { eq: true }
                categories: { elemMatch: { id: { in: $category } } }
            }
        ) {
            nodes {
                ...SanityProductFragment
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;

export default Catalogue;
