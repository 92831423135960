import { Box, Text } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import useLocale from '../lib/useLocale';
import { SanityProduct } from '../../graphql-types';
import formatMoney from '../lib/formatMoney';
import ImageWithCropAndHotspot from './ImageWithCropAndHotspot';
import ProductBadge from './ProductBadge';

interface Props {
    product: SanityProduct;
}

export default function ProductCard({ product }: Props) {
    const { translate } = useLocale();
    const productURL = `/productos/${product?.slug?.current || ''}`;
    if (!product.title) return null;

    const description = product.description
        ? translate(product.description)
        : '';
    const price = (product.price || 0) / 100;

    return (
        <Box
            w="100%"
            overflow="hidden"
            willChange="transform"
            position="relative"
            transition=".3s ease-in-out"
            role="group"
            _hover={{
                transform: 'scale(1.1)',
                color: 'primary.600',
            }}
            as={GatsbyLink}
            to={productURL}
        >
            <Box
                w="100%"
                h="20rem"
                overflow="hidden"
                willChange="transform"
                position="relative"
                rounded="lg"
                display="grid"
            >
                {product?.images?.[0]?.asset && product?.title && (
                    <ImageWithCropAndHotspot
                        image={product.images[0].asset.gatsbyImageData}
                        hotspot={product.images[0].hotspot}
                        crop={product.images[0].crop}
                        alt={translate(product.title)}
                        style={{ height: '20rem' }}
                    />
                )}
            </Box>
            <Box py="2">
                {!!product.state && <ProductBadge state={product.state} />}
                <Text
                    fontSize="lg"
                    color="gray.600"
                    mb={1}
                    _groupHover={{
                        color: 'primary.600',
                    }}
                >
                    {product.title ? translate(product.title) : ''}
                </Text>
                <Text fontSize="xl">{formatMoney(product.price || 0)}</Text>
            </Box>
            <Box display="none">
                <button
                    className="snipcart-add-item"
                    type="button"
                    data-item-id={product._id}
                    data-item-price={price}
                    data-item-url={`/productos/${product?.slug?.current}`}
                    data-item-description={description}
                    data-item-image={
                        product?.images?.[0]?.asset?.gatsbyImageData?.images
                            ?.fallback?.src
                    }
                    // data-item-name={product.title.es}
                    data-item-name={translate(product.title)}
                    data-item-max-quantity={1}
                >
                    Hidden button
                </button>
            </Box>
        </Box>
    );
}
