import { Badge } from '@chakra-ui/react';
import useLocale from '../lib/useLocale';

export default function ProductBadge({ state }: { state: string }) {
    const { format } = useLocale();
    let bgColor = 'gray';
    let text = format('state.outOfStock');
    if (state === 'new') {
        bgColor = 'green';
        text = format('state.new');
    }
    if (state === 'sale') {
        bgColor = 'red';
        text = format('state.sale');
    }
    return (
        <Badge
            rounded="full"
            px="2"
            mb={1}
            fontSize="0.8em"
            colorScheme={bgColor}
        >
            {text}
        </Badge>
    );
}
