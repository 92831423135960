import { Box, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import useLocale from '../lib/useLocale';

export default function OutOfStock() {
    const { format } = useLocale();
    return (
        <Box textAlign="center" py={10} px={6}>
            <Heading as="h2" size="xl" mb={4}>
                {format('outOfStock')}
            </Heading>
            <StaticImage
                src="../images/joey2.png"
                alt="Joey the pug"
                placeholder="blurred"
                objectFit="contain"
                width={360}
            />
            <Text color="gray.500" mt={4} whiteSpace="pre-line">
                {format('weCurrentlyDoNotHaveTheseProducts')}
            </Text>
        </Box>
    );
}
